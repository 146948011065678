<template>
  <div class="container">
    <swiper :options="swiperOption" ref="mySwiper" class="swipper-content">
      <swiper-slide class="swiper-slide">
        <div class="first-page-wrapper">
          <div class="animation-logo-wrapper" ref="lottieContainer"></div>
          <img class="slogon-wrapper" src="../assets/image/slogon.png" />
          <img class="more-wrapper" src="../assets/image/slide_more.png" />
          <img
            class="charge-img"
            src="../assets/image/first_charge.png"
            @click="goCharge()"
          />
          <swiper
            :options="swiperOption2"
            ref="mySwiper2"
            class="swipper-content2"
          >
            <swiper-slide
              v-for="(item, index) in swiperList"
              :key="index"
              class="swiper-slide2"
            >
              <img :src="item.image" class="swiper-bottom-wrapper" />
            </swiper-slide>
          </swiper>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="second-page-wrapper">
          <img class="logo-wrapper" src="../assets/image/meoo_logo.png" />
          <img class="pic-wrapper" src="../assets/image/mobile_pic.webp" />
          <img class="beauty-wrapper" src="../assets/image/beauty_bg.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="third-page-wrapper">
          <img class="logo-wrapper" src="../assets/image/meoo_logo.png" />
          <img class="pic-wrapper" src="../assets/image/mobile_pic2.webp" />
          <img class="beauty-wrapper" src="../assets/image/short_play.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="four-page-wrapper">
          <img class="logo-wrapper" src="../assets/image/meoo_logo.png" />
          <img class="pic-wrapper" src="../assets/image/mobile_pic3.webp" />
          <img class="beauty-wrapper" src="../assets/image/send_gift.png" />
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="five-page-wrapper">
          <div class="join-wrapper"></div>
        </div>
      </swiper-slide>
      <swiper-slide class="swiper-slide">
        <div class="six-page-wrapper">
          <img class="pic-wrapper2" src="../assets/image/mobile_pic4.webp" />
          <div class="right-wrapper">
            <img class="slogon2-wrapper" src="../assets/image/slogon2.png" />
            <div class="down-wrapper">
              <div class="button-wrapper">
                <img
                  class="button-top"
                  src="../assets/image/appstore.png"
                  @click="redirectToAppStore()"
                />
                <img
                  class="button-bottom"
                  src="../assets/image/googleplay.png"
                  @click="redirectToGooglePlay()"
                />
              </div>
            </div>
          </div>
          <div class="name-wrapper3"></div>
        </div>
      </swiper-slide>
    </swiper>
    <div class="tab-wrapper">
      <div class="tabs" ref="tabs">
        <div
          class="tab-item"
          v-for="(tab, index) in tabs"
          :key="index"
          :class="{ active: index === activeIndex }"
          @click="changeSlide(index)"
        >
          {{ tab }}
          <div class="tab-indicator" v-if="index === activeIndex"></div>
        </div>
        <div class="tab-line"></div>
      </div>
    </div>
    <div v-if="isShowPop" class="join-pop" @click="showJoin()">
      <div class="pop"></div>
    </div>
  </div>
</template>

<script>
import lottie from "lottie-web";
import QRCode from "qrcode";
import animationData from "../assets/meoo_animation.json"; // 导入JSON动画文件
import logoUrl from "../assets/meoo.png";

export default {
  data() {
    return {
      tabs: ["首頁", "智能美顏", "優質短劇", "趣味禮物", "合作主播", "下載APP"],
      swiperList: [
        {
          image: require("@/assets/image/carousel01.webp"),
        },
        {
          image: require("@/assets/image/carousel02.webp"),
        },
        {
          image: require("@/assets/image/carousel03.webp"),
        },
        {
          image: require("@/assets/image/carousel04.webp"),
        },
        {
          image: require("@/assets/image/carousel05.webp"),
        },
        {
          image: require("@/assets/image/carousel06.webp"),
        },
      ],
      activeIndex: 0,
      qrCodeImage: "",
      isShowPop: false,
      swiperOption: {
        slidesPerView: "auto",
        direction: "vertical",
        normalizeSlideIndex: false,
        on: {
          slideChange: () => {
            this.activeIndex = this.$refs.mySwiper.swiper.activeIndex;
            this.$nextTick(() => {
              const tabElement =
                this.$refs.tabs.getElementsByClassName("tab-item")[
                  this.activeIndex
                ];
              tabElement.scrollIntoView({
                behavior: "smooth",
                block: "nearest",
                inline: "start",
              });
            });
          },
        },
      },
      swiperOption2: {
        slidesPerView: "auto",
        spaceBetween: 10,
        normalizeSlideIndex: false,
        loop: true,
      },
    };
  },
  created() {
    window.document.title = "Meoo Live";
  },
  mounted() {
    this.loadAnimation();
    this.generateQRCodeWithLogo();
    window.addEventListener("wheel", (event) => {
      if (event.deltaY > 0) {
        if (this.activeIndex < 5) {
          this.changeSlide(this.activeIndex + 1);
        }
      } else {
        if (this.activeIndex > 0) {
          this.changeSlide(this.activeIndex - 1);
        }
      }
    });
  },
  methods: {
    changeSlide(index) {
      this.activeIndex = index;
      this.$refs.mySwiper.swiper.slideTo(index);
    },
    goCharge() {
      window.location.href = "https://webpay.meoo.live/";
    },
    loadAnimation() {
      const animation = lottie.loadAnimation({
        container: this.$refs.lottieContainer,
        renderer: "svg",
        loop: true,
        autoplay: true,
        animationData: animationData, // JSON动画数据
      });
      animation.setSpeed(0.7);
    },
    redirectToAppStore() {
      window.location.href = "https://apps.apple.com/app/meoo/id6471481453";
    },
    redirectToGooglePlay() {
      window.location.href =
        "https://play.google.com/store/apps/details?id=com.ufun.livestreaming";
    },
    showJoin() {
      this.isShowPop = !this.isShowPop;
    },
    async generateQRCodeWithLogo() {
      const qrCodeData = "https://mycdn02.tianzhuwoye.com/meoo.apk"; // 二维码内容

      const canvas = document.createElement("canvas");
      await QRCode.toCanvas(canvas, qrCodeData, {
        errorCorrectionLevel: "H",
        margin: 1,
        borderRadius: 10,
      });

      const ctx = canvas.getContext("2d");
      const logo = new Image();
      logo.src = logoUrl;
      logo.onload = () => {
        const logoSize = canvas.width * 0.2; // 设置logo大小
        const x = (canvas.width - logoSize) / 2;
        const y = (canvas.height - logoSize) / 2;
        ctx.drawImage(logo, x, y, logoSize, logoSize);

        this.qrCodeImage = canvas.toDataURL();
      };
    },
  },
};
</script>
<style lang="less">
html,
body,
#app,
#app > div {
  width: 100%;
  height: 100% !important;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.container {
  width: 100%;
  height: 100%;
}

.tab-wrapper {
  position: fixed;
  top: 30.6vh; /* 距离屏幕顶部2/3位置 */
  right: 60px;
  width: 8vw;
  z-index: 10;
}

.charge-img {
  position: absolute;
  right: 50px;
  top: 50px;
  width: 16vh;
  height: auto;
  cursor: pointer;
  animation: zoomInOut 1s infinite;
}
@keyframes zoomInOut {
  0%,
  100% {
    transform: scale(1); /* 原始大小 */
  }
  50% {
    transform: scale(1.1); /* 放大到1.5倍 */
  }
}
.tabs {
  width: 8vw;
  position: relative;
  padding-top: 20px;
  display: flex;
  flex-direction: column;
}

.tabs ::-webkit-scrollbar {
  display: none;
}

.tabs .tab-item {
  padding: 1vh;
  cursor: pointer;
  position: relative;
  margin: 0 5px;
  font-weight: 400;
  font-size: 0.1rem;
  color: rgba(255, 255, 255, 0.4);
}

.tab-line {
  position: absolute;
  top: 0;
  right: 0;
  width: 2px;
  height: 30vh;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    #ffffff 49%,
    rgba(255, 255, 255, 0) 100%
  );
  border-radius: 0px 0px 0px 0px;
}

.tab-indicator {
  position: absolute;
  width: 10px;
  height: 10px;
  background-image: url("../assets/image/dot.png");
  background-size: cover;
  background-repeat: no-repeat;
  top: 50%;
  transform: translateY(-50%);
  right: -9px; /* 调整圆圈位置 */
}

.tabs .active {
  font-size: 0.12rem;
  color: #ffffff;
  bottom: 6px;
}
.swipper-content {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  z-index: 1;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #000;
  width: 100% !important;
}

.first-page-wrapper {
  width: 100%;
  height: 100%;
  background-image: url("../assets/image/web_bg_one.webp");
  background-size: cover;
  display: flex;
  align-items: center;
  flex-direction: column;

  .animation-logo-wrapper {
    width: 40vw;
    height: 30vh;
    margin-top: 10vh;
  }

  .slogon-wrapper {
    width: 25vw;
    height: 10vh;
    margin-top: 1%;
  }

  .more-wrapper {
    width: 6vw;
    height: 5vh;
    margin-top: 2%;
  }

  .swipper-content2 {
    position: absolute;
    bottom: 10px;
    height: 22vh;
    width: 100%;

    .swiper-slide2 {
      width: calc(100% / 5 - 100px) !important;
      height: 1000%;
      background: none;
    }

    .swiper-bottom-wrapper {
      width: 95%;
      background-size: cover;
      background-repeat: no-repeat;
    }
  }
}

.second-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_two.webp");
  background-size: cover;
  display: flex;
}

.third-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_two.webp");
  background-size: cover;
  display: flex;
}

.four-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_two.webp");
  background-size: cover;
  display: flex;
}

.six-page-wrapper {
  width: 100%;
  height: 100%;
  overflow: auto;
  background-image: url("../assets/image/mobile_bg_six.webp");
  background-size: cover;
  display: flex;

  .slogon2-wrapper {
    width: 100%;
    height: auto;
    margin-top: 20vh;
  }

  .down-wrapper {
    box-sizing: border-box;
    display: flex;
    max-width: 100vw;
    max-height: 60vh;
    margin-top: 2vh;

    .qr-wrapper {
      flex: 2;
      width: 10vw;
      height: 10vw;
    }
    .button-wrapper {
      flex: 3;
      display: flex;
      flex-direction: column;
      margin-left: 30px;

      .button-top {
        width: 17vw; /* 设置图片宽度 */
        height: auto; /* 自适应高度 */
        margin-top: 6px;
      }

      .button-bottom {
        width: 17vw; /* 设置图片宽度 */
        height: auto; /* 自适应高度 */
        margin-top: 2vh;
      }
    }
  }

  .name-wrapper2 {
    font-family: PingFang SC, PingFang SC;
    font-weight: 400;
    font-size: 10px;
    color: rgba(255, 255, 255, 0.6);
    line-height: 12px;
    margin-top: 14px;
    padding-left: 15px;
    margin-bottom: 50px;
  }
}

.five-page-wrapper {
  width: 100vw;
  height: 100vh;
  background-image: url("../assets/image/mobile_bg_five.webp");
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */

  .join-wrapper {
    width: 20vw;
    height: 35vh;
  }
}

.logo-wrapper {
  position: absolute;
  width: 220px;
  height: 220px;
  left: 70px;
  top: 59px;
}

.pic-wrapper {
  max-width: 35%;
  max-height: 70%;
  margin-top: 7%;
  margin-left: 15%;
}

.pic-wrapper2 {
  max-width: 25%;
  max-height: 50%;
  margin-top: 12%;
  margin-left: 20%;
}

.name-wrapper {
  text-align: left;
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  line-height: 12px;
  margin-top: 42px;
  padding-left: 15px;
}

.right-wrapper {
  max-width: 30vw;
  max-height: 30vh;
  margin-top: 14vh;
  margin-left: 5vw;
}

.beauty-wrapper {
  max-width: 35%;
  max-height: 40%;
  margin-top: 15%;
  margin-left: 50px;
}

.name-wrapper3 {
  position: absolute;
  right: 1vw;
  bottom: 1vh;
  width: 15vw;
  height: 4vh;
  background-image: url("../assets/image/name.png");
  background-size: 100%, 100%;
  background-repeat: no-repeat;
  z-index: 20;
}

.join-pop {
  position: fixed;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居上 */
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 100;
}

.pop {
  width: 35vw;
  height: 40vh;
  background-image: url("../assets/image/join_bg.png");
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}
</style>
